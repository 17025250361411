<template>
  <v-layout row wrap align-space-around justify-center fill-height>
    <v-flex xs12 lg12 xl12>
      <v-card>
        <titleCard title="Historial de pedidos" :subtitle="`Tiene ${orders.length} pedidos en total`"></titleCard>
        <v-divider></v-divider>
        <v-data-table
          :headers="headers"
          :items="orders"
          class="elevation-0 order-table"
          color="secondary"
          :disable-initial-sort="true"
          :pagination.sync="pagination"
          :rows-per-page-items="rowsPerPage"
          :loading="loading"
        >
          <template slot="items" slot-scope="props">
            <tr>
              <td>
                <v-btn color="primary" :to="{ name: 'orderHome', params: { id: props.item.id } }" small>
                  {{ props.item.id }}
                  <v-icon right dark small>launch</v-icon>
                </v-btn>
              </td>
              <td>
                <StatusLabel :status-i-d="props.item.status" :status="states"></StatusLabel>
              </td>
              <td>{{ props.item.paymentMethod }}</td>
              <td class="text-xs-right">
                {{ props.item.total | toPrice }}
              </td>
              <td class="text-xs-left">
                {{ props.item.date_shipping | moment('dd DD - MMMM - YYYY') }}<br />
                {{ props.item.schedule }} <br />
                {{ props.item.state }}
              </td>
              <td>
                <v-btn
                  class="ma-1"
                  :color="status(props.item.document.active)"
                  small
                  icon
                  @click="goOrder('orderHome', props.item.id)"
                >
                  <v-icon small>
                    file_copy
                  </v-icon>
                </v-btn>
                <v-btn
                  class="ma-1"
                  :color="status(props.item.print.active)"
                  icon
                  small
                  @click="goOrder('orderHome', props.item.id)"
                >
                  <v-icon small>
                    print
                  </v-icon>
                </v-btn>
                <v-btn
                  class="ma-1"
                  :color="status(props.item.delivery.active)"
                  small
                  icon
                  @click="goOrder('orderLocation', props.item.id)"
                >
                  <v-icon small>
                    local_shipping
                  </v-icon>
                </v-btn>
                <v-btn
                  class="ma-1"
                  :color="status(props.item.design.active)"
                  small
                  icon
                  @click="goOrder('orderDesign', props.item.id)"
                >
                  <v-icon small>
                    local_florist
                  </v-icon>
                </v-btn>
              </td>
            </tr>
          </template>
        </v-data-table>
      </v-card>
    </v-flex>
  </v-layout>
</template>

<script>
import titleCard from '../useful/titleCard.vue'
import StatusLabel from '../useful/statusLabel.vue'
import { CLIENT } from '../../config'

export default {
  components: { titleCard, StatusLabel },
  props: ['buyer'],
  data() {
    return {
      orders: [],
      headers: [
        { text: 'Pedido', align: 'left', sortable: false },
        { text: 'Estado', sortable: false },
        { text: 'Forma de pago', align: 'left', sortable: false },
        { text: 'Total', align: 'right', sortable: false },
        { text: 'Fecha de entrega', sortable: false },
        { text: '', sortable: false }
      ],
      pagination: { rowsPerPage: 50 },
      rowsPerPage: [25, 50, 100, 200, { text: 'Todos', value: -1 }],
      filter: {
        date: '',
        status: 0,
        schedule: ''
      },
      loading: false
    }
  },
  computed: {
    states() {
      return this.$store.getters.getStates
    }
  },
  mounted() {
    this.getOrders()
  },
  methods: {
    async getOrders() {
      this.loading = true
      try {
        const res = await this.$http.get(
          `${CLIENT}/${this.buyer.id}/orders?${this.$qs.stringify({
            filter: this.filter,
            all: true
          })}`
        )
        this.orders = res.data
        this.loading = false
      } catch (error) {
        this.$store.dispatch('changeSnack', {
          active: true,
          text: 'Ocurrio un error, intente nuevamente'
        })
      }
    },
    status(e) {
      if (e) {
        return 'success'
      }
      return 'error'
    },
    goOrder(nameRoute, id) {
      this.$router.push({ name: nameRoute, params: { id } })
    },
    setStatus(statusID) {
      return this._.filter(this.states, ['id', statusID])
    }
  }
}
</script>

<style></style>
