<template>
  <span>
    <v-chip label :color="color" text-color="white" small>{{ name }}</v-chip>
  </span>
</template>

<script>
export default {
  props: ['status', 'statusID'],
  computed: {
    name() {
      const status = this._.filter(this.status, ['id', this.statusID]);
      return status[0].name;
    },
    color() {
      const status = this._.filter(this.status, ['id', this.statusID]);
      return status[0].color;
    },
  },
};
</script>

<style>

</style>
